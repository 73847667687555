.splash-container{display: flex; flex-direction: column; align-items: center;
  .title-container{margin: 3em 0;}
    h2{ text-align: center; }
    p{ font-size: 16px; margin: 0 0 0.5em 0;}
    ul{
      li{font-size: 16px;}
    }
  .users-container{width: 80%; display: flex; justify-content: space-between; 
    .user-item{ padding: 8px; margin:0 1em; cursor: pointer; background: white;
      img{width: 100%; height: auto; display: block;}
      h3{font-weight: bold; text-align: center; margin: 8px;}
      &:hover{border: 1px solid lightgray;}   
    }
  }
}